var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.detailsData.title || '')+" ")]),(_vm.detailsData.items && _vm.detailsData.items.length > 0)?_c('div',{staticClass:"detailsMass-item-iter"},[_c('div',{staticClass:"iter clearfix"},_vm._l((_vm.detailsData.items),function(item,index){return _c('div',{key:index,class:!item.items ? 'otherData clearfix' : item.items ? 'threeData clearfix' : ''},[_c('p',{staticClass:"float-l",style:({ lineHeight: item.height + 'px', height: item.height + 'px', overflow: 'hidden' })},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.items && item.items.length > 0)?_c('div',{staticClass:"threeData-item float-l clearfix"},_vm._l((item.items),function(subItem,subIndex){return _c('div',{key:subIndex},[(item.title != '报告地区' && item.title != '发生地区')?_c('div',[_c('p',{staticStyle:{"width":"100%"}},[_vm._v(_vm._s(subItem.value && subItem.value.toString()))])]):_c('div',[_c('p',{staticClass:"float-l",style:({
									lineHeight: subItem.height + 'px',
									height: subItem.height + 'px',
								})},[_vm._v(" "+_vm._s(subItem.title)+" ")]),(subItem.items && subItem.items.length != 0)?_c('div',{staticClass:"threeData-item-item float-l"},_vm._l((subItem.items),function(child,childIndex){return _c('div',{key:childIndex,staticClass:"clearfix"},[_c('p',{staticClass:"float-l",style:({
											width: '50%',
											lineHeight: child.height + 'px',
											height: child.height + 'px',
										})},[_vm._v(" "+_vm._s(child.title)+"111 ")]),(child.items && child.items.length != 0)?_c('div',{staticClass:"threeData-item-item-item float-l"},_vm._l((child.items),function(subchild,subchildIndex){return _c('p',{key:subchildIndex},[_vm._v(_vm._s(subchild.title)+"1111")])}),0):_c('div',{staticClass:"threeData-item-item-item float-l"},[_c('p',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(child.value && child.value.toString())+" ")])])])}),0):_c('div',{staticClass:"threeData-item-item float-l"},[_c('p',{staticStyle:{"width":"100%"}},[_vm._v(_vm._s(subItem.value && subItem.value.toString()))])])])])}),0):_vm._e()])}),0)]):_c('div',{staticClass:"nullData"},[_vm._v("暂无数据")])])}
var staticRenderFns = []

export { render, staticRenderFns }